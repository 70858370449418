import React from 'react'
import Layout from '../components/layout'
import SubHero from '../components/SubHero'
import Typed from 'react-typed'

const NotFoundPage = () => (
  <Layout>
    <SubHero title="NOT FOUND" subtitle="" />
    <div className="ng-terminal">
      <Typed
        strings={[
          '&#60;div class="ng-404-container"&#62;<br/><br/>&nbsp;&nbsp;&nbsp;&nbsp;&#60;p&#62;You just hit a route that doesn\'t exist... the sadness.&#60;/p&#62;<br/><br/>&#60;/div&#62;<br/><br/>&#60;!--<br/><br/> tango says hi : ) <br/><br/>--&#62;',
        ]}
        typeSpeed={30}
      />
    </div>
  </Layout>
)

export default NotFoundPage
